import React from 'react'
import { SidepanelCard } from '../SidepanelCard'
import {
  type SidepanelGlobalPersonFragment,
  type SidepanelPersonFragment,
  type SidepanelPrivatePersonFragment
} from '@graphqlTypes'
import { SidepanelEntityKeyMetaDetails } from '../SidepanelEntityKeyMetaDetails'
import { EntityLink } from '../../EntityLink/EntityLink'
import { SidepanelSanctionsAlert } from '../SidepanelEntityAlerts'
import { SidepanelGlobalEntityBanner } from '@components/Sidepanel/SidepanelCards/SidepanelGlobalEntityBanner'
import { extractIsGlobalEntity } from '@strise/app-shared'
import { useIsMobile } from '@utils/hooks'
import { SidepanelTab } from '@utils/urls'

interface SidepanelPersonHeaderCardProps {
  person: SidepanelPersonFragment | SidepanelPrivatePersonFragment | SidepanelGlobalPersonFragment
}

export const SidepanelPersonHeaderCard = React.forwardRef<HTMLDivElement, SidepanelPersonHeaderCardProps>(
  ({ person }, ref) => {
    const isMobile = useIsMobile()
    const globalv2Person = person.__typename == 'Person' && person.isGlobalPerson
    const showGlobalEntityBanner = !isMobile && (extractIsGlobalEntity(person) || globalv2Person)

    return (
      <>
        {showGlobalEntityBanner && <SidepanelGlobalEntityBanner v2={globalv2Person} />}
        <SidepanelCard className='relative' ref={ref} tab={SidepanelTab.Person}>
          <div className='flex flex-col gap-4 p-4'>
            <div>
              <EntityLink
                variant='h3'
                entity={person}
                iconWrapperProps={{
                  className: 'p-2 pl-0 mr-1'
                }}
                withIcon
                noTooltip
                noLink
              />
            </div>
            <div>
              <SidepanelEntityKeyMetaDetails entity={person} />
              <SidepanelSanctionsAlert className='mt-2' entityId={person.id} />
            </div>
          </div>
        </SidepanelCard>
      </>
    )
  }
)
