import * as React from 'react'
import { HomeCard } from '@views/Home/components/HomeCard'
import { IconChat, IconHelp, IconPortfolio, IconReview, IconSettings, IconGrow } from '@strise/ui-components'
import { t } from '@lingui/macro'
import { HomeGreetingCard } from '@views/Home/components/HomeGreetingCard'
import { HomeCardActions } from '@views/Home/components/HomeCardActions'
import { useCurrentUser } from '@strise/app-shared'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useHelpCenterLink } from '@utils/userHooks'
import { SettingsModalContext } from '@views/Settings/SettingsModalContextProvider'
import { openChat } from '@utils/intercom'
import { useContext } from '@strise/react-utils'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { contentViewToFeatureMap } from '@utils/contentViewUtils'
import { ContentViews } from '@utils/urls'

export const HomeView = () => {
  const user = useCurrentUser()
  const features = useCurrentUserFeatures()
  const team = useTeam()
  const helpCenterLink = useHelpCenterLink()
  const { setIsSettingsOpen } = useContext(SettingsModalContext)

  const menuPaths = [
    {
      title: t`Portfolio`,
      icon: <IconPortfolio />,
      description: t`Get an overview of all companies in your team portfolio`,
      path: ContentViews.Portfolio
    },
    {
      title: t`Review`,
      icon: <IconReview />,
      description: t`Onboard and conduct due diligence on your customers`,
      path: ContentViews.Review
    },

    {
      title: t`Grow`,
      icon: <IconGrow />,
      description: t`Target and find new prospects`,
      path: ContentViews.Grow
    }
  ]

  const cardActions = [
    {
      title: t`Help Center`,
      icon: <IconHelp />,
      url: helpCenterLink
    },
    {
      title: t`Settings`,
      icon: <IconSettings />,
      onClick: () => {
        setIsSettingsOpen(true)
      }
    },
    {
      title: t`Chat`,
      icon: <IconChat />,
      onClick: () => openChat()
    }
  ]

  const enrichedMenuPaths = menuPaths.map((menuPath) => {
    const feature = contentViewToFeatureMap[menuPath.path]
    return {
      ...menuPath,
      hide: feature ? !features[feature] : false
    }
  })

  const visibleMenuPaths = enrichedMenuPaths.filter((item) => !item.hide)
  const visibleMenuPathsCount = visibleMenuPaths.length

  const cardStyle = () => {
    if (visibleMenuPathsCount === 1) {
      return {
        boxWrapper:
          'flex items-center justify-center relative legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[1000px]',
        boxWrapperGreetings: 'col-span-2',
        boxWrapperHome: 'grid grid-cols-2 place-content-center max-w-[81rem]',
        boxWrapperCardHome: 'col-span-2 md:col-span-1 justify-items-start content-start p-2 max-w-[37.5rem]',
        boxWrapperCardActions:
          'grid grid-cols-1 col-span-2 md:col-span-1 gap-2 justify-items-start content-start p-2 max-w-[37.5rem]'
      }
    }
    if (visibleMenuPathsCount === 2) {
      return {
        boxWrapper: 'relative legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[1000px]',
        boxWrapperHome: 'auto-rows grid grid-flow-row place-content-center px-2',
        boxWrapperCardHome: 'grid grid-cols-1 md:grid-cols-2 gap-4 max-w-[81rem] justify-items-stretch py-6',
        boxWrapperCardActions: 'grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[81rem] justify-items-stretch py-6'
      }
    }

    return {
      boxWrapper: 'relative legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[1000px]',
      boxWrapperHome: 'auto-rows grid grid-flow-row place-content-center px-2',
      boxWrapperCardHome: 'grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[81rem] justify-items-stretch py-6',
      boxWrapperCardActions: 'grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[81rem] justify-items-stretch py-6'
    }
  }

  const { boxWrapper, boxWrapperCardActions, boxWrapperCardHome, boxWrapperGreetings, boxWrapperHome } = cardStyle()
  return (
    <div className={boxWrapper}>
      <div className={boxWrapperHome}>
        <div className={boxWrapperGreetings}>
          <HomeGreetingCard team={team.name} userName={user.name} />
        </div>

        <div className={boxWrapperCardHome}>
          {visibleMenuPaths.map((item, index) => (
            <HomeCard
              key={index}
              title={item.title}
              description={item.description}
              icon={item.icon}
              url={`/${item.path}`}
            />
          ))}
        </div>
        <div className={boxWrapperCardActions}>
          {cardActions.map((item, index) => (
            <HomeCardActions key={index} icon={item.icon} title={item.title} onClick={item.onClick} url={item.url} />
          ))}
        </div>
      </div>
    </div>
  )
}
