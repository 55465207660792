import { t } from '@lingui/macro'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import * as React from 'react'
import { type BaseEntityLikeFragment, type EntityLikeMetaFragment } from '@graphqlTypes'
import { EntityLink } from '../../EntityLink/EntityLink'
import { EntityKeyMetaItems } from '@components/Ownerships/EntityKeyMetaItems'
import { Typography } from '@strise/ui-components'
import { TestIDs } from '@utils/testIDs'
import { EntitySearchInput } from '@components/Entity/EntitySearchInput'
import { extractIsPerson } from '@strise/app-shared'
import { ApplicationSearchReturnType } from '@strise/types'
import { EntityLocationFilterKind } from '@components/Search/searchUtils'

export const MergeEntityDialog = ({
  entity,
  handleCancel,
  handleMerge,
  loading
}: {
  entity: EntityLikeMetaFragment
  handleCancel: () => void
  handleMerge: () => void
  loading: boolean
}) => {
  const isPerson = extractIsPerson(entity)

  const [selectedEntity, setSelectedEntity] = React.useState<BaseEntityLikeFragment | null>(null)

  return (
    <ConfirmDialog
      isOpen={true}
      onClose={handleCancel}
      title={t`Merge entity ${entity.name}`}
      onConfirm={handleMerge}
      confirmText={t`Save`}
      cancelText={t`Cancel`}
      onCancel={handleCancel}
      confirmButtonProps={{
        palette: 'primary',
        'data-track': 'Edit ownership / Merge Entity / Confirm',
        'data-id': TestIDs.SidePanel.Ownerships.mergeEntityConfirmButton
      }}
      cancelButtonProps={{ 'data-track': 'Edit ownership / Merge Entity / Cancel' }}
      loading={loading}
      contentMaxWidth={800}
    >
      <div className='mb-8 flex flex-col gap-3 border border-gray-15 p-4'>
        <EntityLink entity={entity} withIcon noTooltip noLink />
        <EntityKeyMetaItems className='flex gap-2' entity={entity} expanded />
      </div>
      <div className='flex flex-col gap-6'>
        <div className='grid gap-8'>
          <Typography variant='subtitle2'>{t`Select entity to merge with`}</Typography>
          <EntitySearchInput
            variant='outlined'
            dataTrack='Merge Entity / Search Entity'
            entityKindFilter={isPerson ? ApplicationSearchReturnType.Person : ApplicationSearchReturnType.Company}
            entityLocationFilters={[EntityLocationFilterKind.GLOBAL]}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            singleSelect={true}
            data-id={TestIDs.SidePanel.Ownerships.searchEntityTrigger}
            itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityResults }}
            inputProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityInput }}
          />
        </div>
      </div>
    </ConfirmDialog>
  )
}
