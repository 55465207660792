import React from 'react'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { OwnerActionDropdown } from './EditOwner'
import { MergeEntityButton } from './MergeEntityButton'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

/**
 * Identify "slim" entities that can be merged
 */
const isMergeableEntity = (entity: EntityLikeMetaFragment) => {
  return entity.__typename === 'Company' && entity.isSlim
}

const EditButton = ({ entity }: { entity: EntityLikeMetaFragment }) => {
  const { USER_ASSISTED_ENTITY_MERGING: userAssistedEntityMergingEnabled } = useCurrentUserFeatures()

  if (userAssistedEntityMergingEnabled && isMergeableEntity(entity)) {
    return <MergeEntityButton entity={entity} />
  }

  return <OwnerActionDropdown entity={entity} />
}

export default EditButton
