import * as React from 'react'
import { useState } from 'react'
import { t } from '@lingui/macro'
import { DropdownMenu } from '../../DropdownMenu'
import { IconConnect } from '@strise/ui-components'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { TestIDs } from '@utils/testIDs'
import { MergeEntityDialog } from './MergeEntityDialog'
import { noop } from 'lodash-es'

export type ActionMode = 'MERGE'

export const MergeEntityButton = ({ entity }: { entity: EntityLikeMetaFragment }) => {
  const [actionMode, setActionMode] = useState<ActionMode | null>(null)

  const menuOptions = [
    {
      title: t`Merge entity`,
      onClick: () => {
        setActionMode('MERGE')
      },
      'data-track': 'Edit Ownership / Merge entity / Open Dialog',
      'data-id': TestIDs.SidePanel.Ownerships.mergeEntityOption,
      hide: false
    }
  ].filter((option) => !option.hide)

  const handleMerge = noop
  const handleDialogClose = () => {
    setActionMode(null)
  }

  return (
    <>
      <DropdownMenu
        menuItems={menuOptions}
        dataTrack='Merge Entity / Open dropdown'
        icon={<IconConnect />}
        buttonProps={{
          className: 'text-accent-blue-main rounded-full border-2 bg-white z-10 hover:bg-white border-primary-main '
        }}
        position='absolute'
        top={-20}
        right={-20}
      />

      {actionMode === 'MERGE' && (
        <MergeEntityDialog entity={entity} handleMerge={handleMerge} handleCancel={handleDialogClose} loading={false} />
      )}
    </>
  )
}
