import * as React from 'react'
import { cn } from '../../utils/className'
import { createContext, useContext } from '@strise/react-utils'
import { Typography } from '../Typography'

import { baseCardVariants, highlightCardVariants } from '../variants/cardVariants'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  action?: 'anchor' | 'onClick' | 'externalLink' | 'none'
  highlight?: 'bottom' | 'top' | 'left' | 'right'
  highlightPalette?: 'primary' | 'secondary' | 'tertiary' | 'danger'
  onClick?: () => void
  palette?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'white' | 'neutral'
  rounded?: 'xl' | 'lg' | 'md' | 'sm' | 'none'
  size?: 'sm' | 'md' | 'lg'
  to?: string
  variant?: 'contained' | 'outlined'
}

interface CardContextProps {
  highlight: 'bottom' | 'top' | 'left' | 'right'
  highlightPalette: 'primary' | 'secondary' | 'tertiary' | 'danger'
  palette: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'white' | 'neutral'
  rounded: 'xl' | 'lg' | 'md' | 'sm' | 'none'
  size: 'sm' | 'md' | 'lg' | 'main'
  variant: 'contained' | 'outlined'
}

const CardContext = createContext<CardContextProps>()
CardContext.displayName = 'CardContext'

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      action = 'none',
      className,
      highlight = 'bottom',
      highlightPalette = 'primary',
      onClick,
      palette = 'white',
      rounded = 'xl',
      size = 'md',
      to,
      variant = 'contained',
      ...props
    },
    ref
  ) => {
    if (action === 'anchor' && !to) {
      throw new Error('Anchor action requires a valid "to" prop')
    }

    if (action === 'onClick' && !onClick) {
      throw new Error('onClick action requires a valid "onClick" prop')
    }

    if (action === 'externalLink' && !to) {
      throw new Error('External link action requires a valid "to" prop')
    }

    const isExternalLinkAction = action === 'externalLink' && to
    const isAnchorAction = action === 'anchor' && to
    const isButtonAction = action === 'onClick' && onClick

    const getWrapperComponent = (): React.ElementType => {
      if (isButtonAction) {
        return 'button'
      }

      if (isExternalLinkAction || isAnchorAction) {
        return 'a'
      }

      return 'div'
    }

    const WrapperComponent = getWrapperComponent()

    const getLinkProps = () => {
      if (isExternalLinkAction) {
        return { href: to, target: '_blank' }
      }

      if (isAnchorAction) {
        return { href: to }
      }

      return {}
    }

    const linkProps = getLinkProps()

    // Memoization of context value to avoid unnecessary re-renders
    const contextValue = React.useMemo(
      () => ({
        variant,
        size,
        palette,
        highlight,
        highlightPalette,
        rounded
      }),
      [variant, size, palette, highlight, highlightPalette]
    )

    return (
      <CardContext.Provider value={contextValue}>
        <WrapperComponent
          onClick={onClick}
          {...linkProps}
          className={cn(
            baseCardVariants({ variant, size, palette, highlight, highlightPalette, rounded }),
            'no-underline hover:no-underline',
            className
          )}
          ref={ref}
          {...props}
        >
          {props.children}
        </WrapperComponent>
      </CardContext.Provider>
    )
  }
)

Card.displayName = 'Card'

export default Card

const CardHighlight = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { highlight: position, highlightPalette: palette } = useContext(CardContext)

    return <div ref={ref} className={cn(highlightCardVariants({ position, palette }), className)} {...props} />
  }
)
CardHighlight.displayName = 'CardHighlight'

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('p-4', className)} {...props} />
  }
)
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('py-4 text-text-primary', className)} {...props} />
  }
)
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    return <Typography ref={ref} variant='body1' className={cn('text-gray-60', className)} {...props} />
  }
)
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('flex flex-col justify-between rounded-b-xl px-4 py-8', className)} {...props} />
    )
  }
)
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex h-12 items-center rounded-b-xl border-none p-4 transition delay-100 duration-200 ease-in-out',
          className
        )}
        {...props}
      />
    )
  }
)
CardFooter.displayName = 'CardFooter'

const CardActionArea = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex items-center justify-between rounded-xl px-4 text-text-primary', className)}
        {...props}
      />
    )
  }
)
CardActionArea.displayName = 'CardActionArea'

const CardAction = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('flex items-center', className)} {...props} />
  }
)
CardAction.displayName = 'CardAction'

export {
  Card,
  CardHeader,
  CardFooter,
  CardHighlight,
  CardTitle,
  CardDescription,
  CardContent,
  CardActionArea,
  CardAction
}
